<template>
  <g>
    <svg:style>
      .cls-10, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-2 {
        fill: none;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#inox-gradient-4);
      }

      .cls-8 {
        fill: url(#inox-gradient-5);
      }

      .cls-9 {
        fill: url(#inox-gradient-6);
      }

      .cls-10 {
        fill: url(#inox-gradient-7);
      }
    </svg:style>
    <linearGradient id="inox-gradient"
      :x1="doorLeftWidth1 + 69.91"
      :y1="doorTopHeight1 +7204.3"
      :x2="doorLeftWidth1 + 69.91"
      :y2="doorTopHeight1 +792.64"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="glass-gradient"
      :x1="doorLeftWidth1 + 69.91"
      :y1="doorTopHeight1 +7199.41"
      :x2="doorLeftWidth1 + 69.91"
      :y2="doorTopHeight1 +797.53"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox"
      :x1="doorLeftWidth1 + 69.91"
      :y1="doorTopHeight1 +77.99"
      :x2="doorLeftWidth1 + 69.91"
      :y2="doorTopHeight1 +73.1"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3" data-name="inox"
      :x1="doorLeftWidth1 + 69.91"
      :y1="doorTopHeight1 +87.75"
      :x2="doorLeftWidth1 + 69.91"
      :y2="doorTopHeight1 +82.88"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-4" data-name="inox"
      :x1="doorLeftWidth1 + 69.91"
      :y1="doorTopHeight1 +68.22"
      :x2="doorLeftWidth1 + 69.91"
      :y2="doorTopHeight1 +63.33"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-5" data-name="inox"
      :x1="doorLeftWidth1 + 69.91"
      :y1="doorTopHeight1 +223.83"
      :x2="doorLeftWidth1 + 69.91"
      :y2="doorTopHeight1 +218.95"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-6" data-name="inox"
      :x1="doorLeftWidth1 + 69.91"
      :y1="doorTopHeight1 +214.06"
      :x2="doorLeftWidth1 + 69.91"
      :y2="doorTopHeight1 +209.18"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-7" data-name="inox"
      :x1="doorLeftWidth1 + 69.91"
      :y1="doorTopHeight1 +233.6"
      :x2="doorLeftWidth1 + 69.91"
      :y2="doorTopHeight1 +228.72"
      xlink:href="#inox-gradient"/>
    <linearGradient id="handle-gradient"
      :x1="doorLeftWidth1 + 11.16"
      :y1="doorTopHeight1 +148.47"
      :x2="doorLeftWidth1 + 16.75"
      :y2="doorTopHeight1 +148.47"
      xlink:href="#inox-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V23">
<!--      <line id="inox" data-name="inox" class="cls-2" :x1="`${doorLeftWidth + 83.87}`" :y1="`${doorTopHeight + 118.46}`" :x2="`${doorLeftWidth + 83.87}`" :y2="`${doorTopHeight + 122.65}`"/>-->
<!--      <line id="inox-2" data-name="inox" class="cls-2" :x1="`${doorLeftWidth + 55.95}`" :y1="`${doorTopHeight + 122.65}`" :x2="`${doorLeftWidth + 55.95}`" :y2="`${doorTopHeight + 118.46}`"/>-->
<!--      <line id="inox-3" data-name="inox" class="cls-2" :x1="`${doorLeftWidth + 83.87}`" :y1="`${doorTopHeight + 108}`" :x2="`${doorLeftWidth + 83.87}`" :y2="`${doorTopHeight + 112.19}`"/>-->
<!--      <line id="inox-4" data-name="inox" class="cls-2" :x1="`${doorLeftWidth + 55.95}`" :y1="`${doorTopHeight + 112.19}`" :x2="`${doorLeftWidth + 55.95}`" :y2="`${doorTopHeight + 108}`"/>-->
<!--      <line id="inox-5" data-name="inox" class="cls-2" :x1="`${doorLeftWidth + 83.87}`" :y1="`${doorTopHeight + 97.53}`" :x2="`${doorLeftWidth + 83.87}`" :y2="`${doorTopHeight + 101.71}`"/>-->
<!--      <line id="inox-6" data-name="inox" class="cls-2" :x1="`${doorLeftWidth + 55.95}`" :y1="`${doorTopHeight + 101.71}`" :x2="`${doorLeftWidth + 55.95}`" :y2="`${doorTopHeight + 97.53}`"/>-->
      <polyline id="inox-7"
                v-if="showInox"
                data-name="inox"
                class="cls-3"
                :points="`${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 92.64} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 204.3} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 204.3} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 92.64} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 92.64}`"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-4"
                :points="`${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 97.53} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 199.41} ${doorLeftWidth1 + 78.99} ${doorTopHeight1 + 199.41} ${doorLeftWidth1 + 78.99} ${doorTopHeight1 + 97.53} ${doorLeftWidth1 + 60.84} ${doorTopHeight1 + 97.53}`"/>
      <polyline id="inox-8"
                v-if="showInox"
                data-name="inox"
                class="cls-5"
                :points="`${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 73.1} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 73.1} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 77.99} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 77.99} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 73.1}`"/>
      <polyline id="inox-9"
                v-if="showInox"
                data-name="inox"
                class="cls-6"
                :points="`${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 82.88} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 82.88} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 87.75} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 87.75} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 82.88}`"/>
      <polyline id="inox-10"
                v-if="showInox"
                data-name="inox"
                class="cls-7"
                :points="`${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 63.33} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 63.33} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 68.22} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 68.22} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 63.33}`"/>
      <polyline id="inox-11"
                v-if="showInox"
                data-name="inox"
                class="cls-8"
                :points="`${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 223.83} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 223.83} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 218.95} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 218.95} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 223.83}`"/>
      <polyline id="inox-12"
                v-if="showInox"
                data-name="inox"
                class="cls-9"
                :points="`${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 214.06} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 214.06} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 209.18} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 209.18} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 214.06}`"/>
      <polyline id="inox-13"
                v-if="showInox"
                data-name="inox"
                class="cls-10"
                :points="`${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 233.6} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 233.6} ${doorLeftWidth1 + 83.87} ${doorTopHeight1 + 228.72} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 228.72} ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 233.6}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
